<template>
  <div>
    <section class="white">
      <v-container>
        <v-row class="pb-5 pt-5">
          <v-col
            cols="10"
            offset="1"
            style="font-family:'Kanit'!important"
          >
            <v-col
              cols="12"
              max-width="500"
              outlined
              tile
              class="mb-5 centered text-justify pure-border"
            >
              <h2
                :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline']"
                class="mb-2"
                style="font-family:'Kanit'!important"
              >
                KIM JESTEŚMY
              </h2>
              <p
                class="subtitle-1 text-justify mb-1"
                style="font-family:'Kanit'!important"
              >
                MISJA I WIZJA
              </p>
            </v-col>
            <v-container class="d-md-inline-flex">
              <v-col
                md="6"
                sm="12"
              >
                <v-card
                  outlined
                  tile
                  class="mb-5 centered text-justify"
                >
                  <v-card-title>
                    MISJA
                  </v-card-title>
                  <v-card-text>
                    Tworzenie i wdrażanie produktów inwestycyjnych opartych na projektach infrastrukturalnych. Spółka skupia się na budowie infrastruktury energetycznej oraz na wdrażaniu w pełnym zakresie projektów energetyki odnawialnej OZE w postaci farm PV, farm wiatrowych, magazynów energii oraz innych zarówno tradycyjnych jak i alternatywnych źródeł energii w oparciu o dedykowane wehikuły inwestycyjne.
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col
                md="6"
                sm="12"
              >
                <v-card
                  outlined
                  tile
                  theme--dark
                  class="mb-5 centered text-justify"
                >
                  <v-card-title>
                    WIZJA
                  </v-card-title>
                  <v-card-text>
                    Produkty, rozwiązania i działania operacyjne oferowane przez POLGRID SA wpisują się w bardzo dynamiczny globalny zwrot w kierunku odnawialnych źródeł energii jak również modernizacji i budowy nowoczesnej, hybrydowej  infrastruktury energetycznej, która sprosta charakterystyce pracy systemów złożonych zapewniając inwestorom godny zysk, a uczestnikom rynku energii bezpieczeństwo i stabilizację.
                  </v-card-text>
                </v-card>
              </v-col>
            </v-container>
          </v-col>
        </v-row>
        <v-row
          class="pb-5 pt-5 background-light-grey"
        >
          <v-col
            cols="10"
            offset="1"
          >
            <v-col
              cols="12"
              max-width="500"
              outlined
              tile
              class="mb-5 centered text-justify pure-border"
            >
              <h2
                :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline']"
                class="mb-2"
                style="font-family:'Kanit'!important"
              >
                PROJEKTY
              </h2>
              <p
                class="subtitle-1 text-justify mb-1"
                style="font-family:'Kanit'!important"
              >
                OBSZAR DZIAŁALNOŚCI
              </p>
            </v-col>
            <v-col cols="12">
              <div class="mb-2">
                <p class="mb-1">
                  Prezentacja struktury spółki Polgrid SA i projektów w przygotowaniu
                  <a
                    href="/static/PolgridSA.pdf"
                    download
                  >[pobierz]
                  </a>
                  <a
                    href="/static/PolgridSA.pdf"
                    target="_blank"
                  >[zobacz]
                  </a>
                </p>
              </div>
            </v-col>
            <v-container class="d-md-inline-flex">
              <v-col
                md="4"
                sm="12"
                class="text-center"
              >
                <v-card>
                  <v-img src="/static/drz.png" />
                </v-card>
              </v-col>
              <v-col
                md="4"
                sm="12"
              >
                <v-card>
                  <v-img src="/static/mag.png" />
                </v-card>
              </v-col>
              <v-col
                md="4"
                sm="12"
              >
                <v-card>
                  <v-img src="/static/rz.png" />
                </v-card>
              </v-col>
            </v-container>
          </v-col>
        </v-row>
        <v-row class="pb-5 pt-5">
          <v-col
            cols="10"
            offset="1"
            style="font-family:'Kanit'!important"
          >
            <v-col
              cols="12"
              max-width="500"
              outlined
              tile
              class="mb-5 centered text-justify pure-border"
            >
              <h2
                :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline']"
                class="mb-2"
                style="font-family:'Kanit'!important"
              >
                POLGRID SA
              </h2>
              <p
                class="subtitle-1 text-justify mb-1"
                style="font-family:'Kanit'!important"
              >
                POLSKI PODMIOT PRYWATNY BUDUJĄCY SIECI ENERGETYCZNE
              </p>
            </v-col>
            <v-container>
              <v-col cols="12">
                <v-card
                  outlined
                  tile
                  class="mb-5 centered text-justify"
                >
                  <v-card-text>
                    Połączenie wysokich kompetencji w branży energetycznej i finansowej pozwala zaproponować bezpieczne produkty inwestycyjne, których obecnie brakuje ze względu na wysoką inflację, realnie ujemne oprocentowanie depozytów oraz panującą na rynkach kapitałowych bessę. Inwestycje w papiery dłużne są niepewne. Obserwujemy upadki i bankructwa przedsiębiorstw.
                    POLGRID SA proponuje unikalny, a zarazem naturalny hedge przeciwko inflacji i wysokim cenom energii oraz spadkowi popytu - recesji i stagflacji. Działalność w branży będącej dostawcą dobra koniecznego, a nie dobra wyboru, eliminuje ryzyko recesji. Inwestycje w branżę energetyczną są konieczne i społecznie uzasadnione.
                  </v-card-text>
                </v-card>
              </v-col>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<style scoped>
  .pure-border {
    border-left: 3px solid black;
  }
  .background-light-grey {
    background-color: #f1f1f1;
  }
</style>

<script>
  export default {
  }
</script>
